@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-Black.woff) format('woff');
  font-weight: 900;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-Bold.woff) format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-ExtraBold.woff) format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-ExtraLight.woff) format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-Light.woff) format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-Medium.woff) format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-SemiBold.woff) format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Pretendard';
  src:  url(./assets/fonts/Pretendard-Thin.woff) format('woff');
  font-weight: 100;
}