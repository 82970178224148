body.no-scroll {
  overflow-y: hidden;
}
.left-menu {
  z-index: 2;

  @media (min-width: 1025px){
    position: fixed;
    left: 0;
  }

  @media (max-width: 1024px){
    position: unset;
  }

  &.open {
    .overlay {
      opacity: 1;
      transform: none;
      transition: opacity 300ms;
    }
    .menu-container {
      transform: translateX(0%);
    }
  }
  .overlay {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    transform: translate(100%, 0px);
    transition: opacity 300ms, transform 0s 0.3s;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
  }

  .menu-container {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    transition: transform 300ms;
    transform: translateX(-100%);
    // background-color: #3998B5;
    height: 100vh;
    overflow-y: auto;
  }

  .menu {
    padding: 10px 21px 10px 25px;
  }

 .item {
    position: relative;
    text-transform: capitalize;
    a {
      padding: 10px 0;
      &.current-link {
        color: #8C64A0;
      }

      &:hover {
        color: inherit;
      }
    }
  }
}
